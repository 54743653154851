"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeLine_TEST = void 0;
exports.toAdif = toAdif;

var _utils = require("./utils");

var result = _interopRequireWildcard(require("./result"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function makeItem(name, textParam) {
  var text = textParam; // TODO handle non-ASCII

  if (text == null) {
    // TODO consider omitting null entries entirely
    text = '';
  }

  return "<".concat(name, ":").concat(text.length, ">").concat(text);
}

function makeHeader() {
  return "hamlogs by nmote ".concat(makeItem('ProgramID', 'hamlogs'), "<EOH>");
}

function makeLine(stationCallSign, myPark, entry) {
  return makeItem('STATION_CALLSIGN', stationCallSign) + makeItem('MY_SIG', 'POTA') + makeItem('MY_SIG_INFO', myPark) + makeItem('CALL', entry.call) + makeItem('QSO_DATE', entry.date.toString()) + makeItem('TIME_ON', entry.time.toString()) + makeItem('BAND', entry.band.toADIFBand()) + makeItem('MODE', entry.mode) + makeItem('SIG_INFO', entry.sigInfo) + '<eor>';
}

function entryToPOTAEntry(entry) {
  var potaResult = {
    call: (0, _utils.maybeToResult)(entry.call, "The other station's callsign must be provided"),
    date: (0, _utils.maybeToResult)(entry.date, 'Date must be provided'),
    time: (0, _utils.maybeToResult)(entry.time, 'Time must be provided'),
    band: (0, _utils.maybeToResult)(entry.band, 'Band or frequency must be included'),
    mode: (0, _utils.maybeToResult)(entry.mode, 'Mode must be included'),
    sigInfo: result.ok(entry.sigInfo)
  };

  if (potaResult.call.kind === 'ok' && potaResult.date.kind === 'ok' && potaResult.time.kind === 'ok' && potaResult.band.kind === 'ok' && potaResult.mode.kind === 'ok' && potaResult.sigInfo.kind === 'ok') {
    return result.ok({
      call: potaResult.call.value,
      date: potaResult.date.value,
      time: potaResult.time.value,
      band: potaResult.band.value,
      mode: potaResult.mode.value,
      sigInfo: potaResult.sigInfo.value
    });
  } else {
    return result.err((0, _utils.extractErrors)((0, _utils.objectValues)(potaResult)));
  }
}

function toAdif(stationCallSign, myPark, log) {
  var potaEntries = (0, _utils.collateErrors)(log.map(entryToPOTAEntry));
  return result.bind(potaEntries, function (entries) {
    var lines = [];
    lines.push(makeHeader());
    entries.forEach(function (entry) {
      lines.push(makeLine(stationCallSign, myPark, entry));
    });
    return result.ok(lines.join('\n') + '\n');
  });
}

var makeLine_TEST = makeLine;
exports.makeLine_TEST = makeLine_TEST;