"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bind = bind;
exports.err = err;
exports.ok = ok;

function ok(x) {
  return {
    kind: 'ok',
    value: x
  };
}

function err(x) {
  return {
    kind: 'err',
    err: x
  };
}

function bind(x, f) {
  if (x.kind === 'ok') {
    return f(x.value);
  } else {
    return x;
  }
}