"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.band = band;
exports.call = call;
exports.date = date;
exports.mode = mode;
exports.notes = notes;
exports.otherSummit = otherSummit;
exports.sigInfo = sigInfo;
exports.time = time;

var result = _interopRequireWildcard(require("../result"));

var _date = require("./date");

var _time = require("./time");

var _band = require("./band");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function date(input) {
  if (input == null) {
    return result.ok(null);
  }

  return (0, _date.parseDate)(input);
}

function time(input) {
  if (input == null) {
    return result.ok(null);
  }

  return (0, _time.parseTime)(input);
}

function band(bandInput, freqInput) {
  if (bandInput != null) {
    return (0, _band.parseBand)(bandInput);
  } else if (freqInput != null) {
    return (0, _band.freqToBand)(freqInput);
  } else {
    return result.ok(null);
  }
} // We'll use ADIF modes here. They may require summarization or modification for
// certain outputs, but that's okay.
//
// See: https://adif.org/311/ADIF_311.htm#Mode_Enumeration
//
// TODO add more modes
// TODO infer mode from submode


var hamModes = new Set(['AM', 'CW', 'FM', 'FT8', 'PSK', 'RTTY', 'SSTV', 'OLIVIA', 'SSB']);

function normalizeMode(input) {
  return input.toUpperCase();
}

function mode(inputParam) {
  var input = inputParam;

  if (input == null) {
    return result.ok(null);
  }

  input = normalizeMode(input);

  if (!hamModes.has(input)) {
    return result.err('Mode must be valid');
  }

  return result.ok(input);
}

function normalizeCall(input) {
  // It's probably futile to try to validate the callsign.
  return input.toUpperCase();
}

function call(input) {
  if (input == null) {
    return result.ok(null);
  }

  return result.ok(normalizeCall(input));
}

function sigInfo(input) {
  // TODO Validate park number for when this is used for POTA
  return result.ok(input);
}

function otherSummit(input) {
  // TODO Validate summit identifier
  return result.ok(input);
}

function notes(input) {
  return result.ok(input);
}