"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toSota = toSota;

var Papa = _interopRequireWildcard(require("papaparse"));

var result = _interopRequireWildcard(require("./result"));

var _utils = require("./utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function entryToSOTAEntry(entry) {
  var sotaResult = {
    call: (0, _utils.maybeToResult)(entry.call, "The other station's callsign must be provided"),
    date: (0, _utils.maybeToResult)(entry.date, 'Date must be provided'),
    time: (0, _utils.maybeToResult)(entry.time, 'Time must be provided'),
    band: (0, _utils.maybeToResult)(entry.band, 'Band or frequency must be included'),
    mode: (0, _utils.maybeToResult)(entry.mode, 'Mode must be included'),
    otherSummit: result.ok(entry.otherSummit),
    notes: result.ok(entry.notes)
  };

  if (sotaResult.call.kind === 'ok' && sotaResult.date.kind === 'ok' && sotaResult.time.kind === 'ok' && sotaResult.band.kind === 'ok' && sotaResult.mode.kind === 'ok' && sotaResult.otherSummit.kind === 'ok' && sotaResult.notes.kind === 'ok') {
    return result.ok({
      call: sotaResult.call.value,
      date: sotaResult.date.value,
      time: sotaResult.time.value,
      band: sotaResult.band.value,
      mode: sotaResult.mode.value,
      otherSummit: sotaResult.otherSummit.value,
      notes: sotaResult.notes.value
    });
  } else {
    return result.err((0, _utils.extractErrors)((0, _utils.objectValues)(sotaResult)));
  }
}

function SOTAEntryToCSVLine(stationCallsign, mySummit, entry) {
  return ['V2', stationCallsign, mySummit, entry.date.toSOTAString(), entry.time.toSOTAString(), entry.band.toSOTABand(), entry.mode, entry.call, entry.otherSummit, entry.notes];
}

function toSota(stationCallsign, mySummit, log) {
  var sotaEntries = (0, _utils.collateErrors)(log.map(entryToSOTAEntry));
  return result.bind(sotaEntries, function (entries) {
    var lines = entries.map(function (entry) {
      return SOTAEntryToCSVLine(stationCallsign, mySummit, entry);
    });
    return result.ok(Papa.unparse(lines) + '\r\n');
  });
}