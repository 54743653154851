"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.columnOrder_TEST = void 0;
exports.parse = parse;

var Papa = _interopRequireWildcard(require("papaparse"));

var entry = _interopRequireWildcard(require("./entry"));

var result = _interopRequireWildcard(require("./result"));

var _utils = require("./utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var canonicalToAlternates = new Map([['date', []], ['time', []], ['band', []], ['mode', []], ['call', []], ['sig_info', ['other_park']], ['other_summit', []], ['notes', ['comments']]]);
var nameToCanonicalName = new Map();

var _iterator = _createForOfIteratorHelper(canonicalToAlternates),
    _step;

try {
  for (_iterator.s(); !(_step = _iterator.n()).done;) {
    var _step$value = _slicedToArray(_step.value, 2),
        canonical = _step$value[0],
        alternates = _step$value[1];

    nameToCanonicalName.set(canonical, canonical);

    var _iterator2 = _createForOfIteratorHelper(alternates),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var alternate = _step2.value;
        nameToCanonicalName.set(alternate, canonical);
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  }
} catch (err) {
  _iterator.e(err);
} finally {
  _iterator.f();
}

function normalizeName(name) {
  return name.toLowerCase().replace(/[ -]/g, '_');
}

function columnOrder(headerRow) {
  var order = new Map();
  headerRow.forEach(function (name, i) {
    var normalized = normalizeName(name);
    var canonical = nameToCanonicalName.get(normalized);

    if (canonical != null) {
      order.set(canonical, i);
    }
  });
  return order;
}

function extractCell(order, row, name) {
  var index = order.get(name);

  if (index == null) {
    return null;
  }

  var result = row[index];

  if (result === '') {
    // The CSV parser gives us the empty string if a cell is empty
    return null;
  }

  return result;
}

function entryResultToEntry(entry) {
  if (entry.date.kind === 'ok' && entry.time.kind === 'ok' && entry.band.kind === 'ok' && entry.mode.kind === 'ok' && entry.call.kind === 'ok' && entry.sigInfo.kind === 'ok' && entry.otherSummit.kind === 'ok' && entry.notes.kind === 'ok') {
    return result.ok({
      date: entry.date.value,
      time: entry.time.value,
      band: entry.band.value,
      mode: entry.mode.value,
      call: entry.call.value,
      sigInfo: entry.sigInfo.value,
      otherSummit: entry.otherSummit.value,
      notes: entry.notes.value
    });
  } else {
    return result.err((0, _utils.extractErrors)((0, _utils.objectValues)(entry)));
  }
}

function parseRow(order, row) {
  var results = {
    date: entry.date(extractCell(order, row, 'date')),
    time: entry.time(extractCell(order, row, 'time')),
    band: entry.band(extractCell(order, row, 'band'), null),
    mode: entry.mode(extractCell(order, row, 'mode')),
    call: entry.call(extractCell(order, row, 'call')),
    sigInfo: entry.sigInfo(extractCell(order, row, 'sig_info')),
    otherSummit: entry.otherSummit(extractCell(order, row, 'other_summit')),
    notes: entry.notes(extractCell(order, row, 'notes'))
  };
  return entryResultToEntry(results);
}

function parse(input) {
  var csvResult = Papa.parse(input, {
    header: false,
    skipEmptyLines: true
  });

  if (csvResult.errors.length > 0) {
    var errors = csvResult.errors.map(function (err) {
      var inRowText = err.row == null ? '' : " in row #".concat(err.row);
      return "Problem parsing CSV".concat(inRowText, ": ").concat(err.message);
    });
    return result.err(errors);
  }

  var csv = csvResult.data;
  var order = columnOrder(csv.shift());
  return (0, _utils.collateErrors)(csv.map(function (entry) {
    return parseRow(order, entry);
  }));
}

var columnOrder_TEST = columnOrder;
exports.columnOrder_TEST = columnOrder_TEST;