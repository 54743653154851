"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.consume = consume;
exports.consumeInt = consumeInt;
exports.parseInteger = parseInteger;
exports.peek = peek;
exports.withParserEnv = withParserEnv;

var result = _interopRequireWildcard(require("./result"));

var _utils = require("./utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function withParserEnv(input, tooLongError, f) {
  var env = {
    input: input,
    i: 0
  };
  return result.bind(f(env), function (v) {
    if (env.i !== env.input.length) {
      return result.err(tooLongError);
    } else {
      return result.ok(v);
    }
  });
}

function consume(env, chars) {
  var end = env.i + chars;

  if (end > env.input.length) {
    return null;
  }

  var str = env.input.substring(env.i, end);
  env.i = end;
  return str;
}

function peek(env, chars) {
  var end = env.i + chars;

  if (end > env.input.length) {
    return null;
  }

  return env.input.substring(env.i, end);
}

function isAllNumbers(str) {
  return /^\d+$/.test(str);
}

function consumeInt(env, chars) {
  var text = consume(env, chars);

  if (text == null || !isAllNumbers(text)) {
    return null;
  }

  return parseInt(text);
}

function parseInteger(env, length) {
  return (0, _utils.maybeToResult)(consumeInt(env, length), null);
}